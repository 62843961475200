













































































































































































































































































































































































































































import { popoStore } from '@/stores/popo-store'
import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'
import { profileController } from '../viewmodels/profile-viewmodel'

@Observer
@Component({
  components: {
    'follow-button': () => import('../activity/pages/components/following/follow-button.vue'),
    'social-link': () => import('@/modules/profile/components/social-link.vue'),
  },
})
export default class UserInformation extends Vue {
  popoStore = popoStore
  profileController = profileController
  selectAvatarInput() {
    const avatarInput = document.getElementById('avatar-input') as any
    avatarInput.click()
  }

  selectCoverImageInput() {
    const coverImageInput = document.getElementById('cover-image-input') as any
    coverImageInput.click()
  }

  get lastPath() {
    const fullPath = this.$route.fullPath
    const paths = fullPath.split('/')
    return paths[paths.length - 1]
  }

  gotoTargetRoute(tab: string, hash = '') {
    const currentParams = this.$router.currentRoute.params.userId
    const targetRoute = `/profile/${currentParams}/activity/${tab}${hash}`
    this.$router.push(targetRoute)
  }
}
